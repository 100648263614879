import React, { useContext, useEffect, useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import { useQuery, useQueryClient } from 'react-query';
import { SttFullDialog } from '../../../sporttia/dialogs/SttFullDialog';
import { SttTabs } from '../../../sporttia/SttTabs';
import { useTranslations } from '../../../lib/hooks';
import { SPORTCENTER_GOLF_COURSE } from '../../../lib/queryKeys';
import useGolfService from '../../../services/GolfService';
import GolfCourseConfiguration from './GolfCourseConfiguration';
import { AppContext } from '../../../sporttia/AppContext';
import GolfCourseSchedulesAndRates from './GolfCourseSchedulesAndRates';

/**
 * Golf course view. Contains tabs to configure the course, holes ...
 * @param open If true, the full dialog opens and closes otherwise.
 * @param id Golf course ID.
 * @param onClose Function executed when closing the full dialog.
 * @returns {JSX.Element}
 */
export default function GolfCourse({ open, id, onClose }) {
    const cxt = useContext(AppContext);
    const { translate } = useTranslations();
    const queryClient = useQueryClient();
    const golfService = useGolfService();
    const [golfCourseId, setGolCoursefId] = useState(id);
    const golfCourseQuery = useQuery(
        [SPORTCENTER_GOLF_COURSE, { id: golfCourseId }],
        () => golfService.getGolfCourse(golfCourseId),
        { enabled: !!golfCourseId && golfCourseId !== 'create' && open },
    );

    /**
     * Update golf course id if id prop was changed.
     */
    useEffect(() => {
        setGolCoursefId(id);
    }, [id]);

    return (
        <SttFullDialog
            open={open}
            onClose={onClose}
            title={
                // eslint-disable-next-line no-nested-ternary
                golfCourseQuery?.isLoading
                    ? ''
                    : golfCourseQuery?.data?.golfCourse?.id
                      ? golfCourseQuery?.data?.golfCourse?.name
                      : translate('golf.createCourse')
            }
        >
            <Paper>
                <Box p={3}>
                    <SttTabs
                        tabs={[
                            {
                                caption: 'Configuracion',
                                component: (
                                    <GolfCourseConfiguration
                                        golfCourse={
                                            golfCourseQuery?.data?.golfCourse
                                        }
                                        status={golfCourseQuery?.status}
                                        onCreate={(data) => {
                                            if (data?.error?.msg) {
                                                cxt.showMessage(
                                                    'E',
                                                    data?.error?.msg,
                                                );
                                            } else {
                                                setGolCoursefId(
                                                    data?.golfCourse?.id,
                                                );
                                                cxt.showMessage(
                                                    'S',
                                                    translate('Created'),
                                                );
                                            }
                                        }}
                                        onUpdate={(data) => {
                                            queryClient.setQueryData(
                                                [
                                                    SPORTCENTER_GOLF_COURSE,
                                                    {
                                                        id: data?.golfCourse
                                                            ?.id,
                                                    },
                                                ],
                                                data,
                                            );
                                            cxt.showMessage(
                                                'S',
                                                translate('Saved'),
                                            );
                                        }}
                                        onDelete={() => {
                                            onClose();
                                            cxt.showMessage(
                                                'S',
                                                translate('Deleted'),
                                            );
                                        }}
                                        onUpdateHole={(data) => {
                                            const updatedData = {
                                                ...golfCourseQuery?.data,
                                            };
                                            const elementIndex =
                                                updatedData?.golfCourse?.holes?.rows.findIndex(
                                                    (hole) =>
                                                        hole.id ===
                                                        data?.golfHole?.id,
                                                );
                                            updatedData.golfCourse.holes.rows[
                                                elementIndex
                                            ] = data?.golfHole;
                                            queryClient.setQueryData(
                                                [
                                                    SPORTCENTER_GOLF_COURSE,
                                                    {
                                                        id: data?.golfCourse
                                                            ?.id,
                                                    },
                                                ],
                                                updatedData,
                                            );
                                            cxt.showMessage(
                                                'S',
                                                translate('Saved'),
                                            );
                                        }}
                                    />
                                ),
                            },
                            {
                                caption: 'Horarios y tarifas',
                                show: !!golfCourseQuery?.data?.golfCourse?.id,
                                component: (
                                    <GolfCourseSchedulesAndRates
                                        golfCourseId={golfCourseId}
                                    />
                                ),
                            },
                        ]}
                    />
                </Box>
            </Paper>
        </SttFullDialog>
    );
}

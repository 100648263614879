import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, LinearProgress } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { AppContext, SttDialog, SttFormSelect } from '../all';
import { useInteractionsFiles } from '../../lib/hooks';
import constants from '../../config/constants';

/**
 * Upload file
 * ===========
 *
 * Props
 *
 * 	* boolean open
 * 	* string[] types: list of file types to select. If there's only only don't show the selector
 * 	* object uploadParams
 * 	* function onClose
 * 	* function onUploaded({file, response})
 */
export function SttUploadDialog({
    open = false,
    types = [],
    uploadParams = {},
    onClose,
    onUploaded,
    legacyMode = false,
}) {
    const cxt = useContext(AppContext);
    const { uploadFile, uploadFileLegacyV6 } = useInteractionsFiles();
    const inputFile = useRef(null);
    const [selectedType, setSelectedType] = useState();
    const [uploading, setUploading] = useState(false);

    /**
     * Init
     */
    useEffect(() => {
        if (types.length) setSelectedType(types[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Open select file
     */
    const openSelectFile = () => {
        inputFile.current.click();
    };

    /**
     * On file selected
     *
     * 	* files: all files selected
     */
    const onFileSelected = (files) => {
        if (files) {
            const file = files[0];

            // TODO: eliminar esta funcionalidad cuando 100% no vaya a volverse a subir ningún fichero a v6 (básicamente, devolución de remesas)
            if (legacyMode) {
                file.arrayBuffer()
                    .then((buffer) => {
                        setUploading(true);
                        uploadFileLegacyV6(
                            {
                                name: file.name,
                                format: file.type,
                                type: selectedType,
                                ...uploadParams,
                            },
                            buffer,
                        ).then((response) => {
                            setUploading(false);
                            cxt.showMessage('S', cxt.t('Uploaded'));
                            if (onUploaded) {
                                onUploaded(response);
                            }
                        });
                    })
                    .catch();
            } else {
                uploadFile(
                    {
                        name: file.name,
                        format: file.type,
                        type: selectedType,
                        ...uploadParams,
                    },
                    file,
                )
                    .then((response) => {
                        setUploading(false);
                        cxt.showMessage('S', cxt.t('Uploaded'));
                        if (onUploaded) {
                            onUploaded(response);
                        }
                    })
                    .catch();
            }
        }
    };

    // -----| Render |-----
    return (
        <>
            <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={(event) => onFileSelected(event.target.files)}
            />

            <SttDialog
                title={cxt.t('UploadFile')}
                open={open}
                onClose={onClose}
                content={
                    <>
                        {types.length === 1 ? (
                            <Box>
                                {cxt.t('Type')}:{' '}
                                {cxt.constants.fileTypes &&
                                    cxt.constants.fileTypes.find(
                                        (ft) => ft.id === types[0],
                                    ).label}
                            </Box>
                        ) : (
                            <SttFormSelect
                                grid
                                md={12}
                                sm={12}
                                xs={12}
                                caption={cxt.t('Type')}
                                defVal={selectedType}
                                onChange={({ value }) => setSelectedType(value)}
                                options={
                                    cxt.constants.fileTypes &&
                                    cxt.constants.fileTypes
                                        .filter((ft) => types.includes(ft.id))
                                        .concat(
                                            constants.fileTypes.filter((ft) =>
                                                types.includes(ft.id),
                                            ),
                                        )
                                }
                            />
                        )}

                        {uploading && (
                            <Box mt={3}>
                                <LinearProgress />
                            </Box>
                        )}
                    </>
                }
                buttons={[
                    {
                        caption: cxt.t('SelectFile'),
                        icon: <AttachFileIcon />,
                        onClick: openSelectFile,
                    },
                    {
                        type: 'close',
                        onClick: onClose,
                    },
                ]}
            />
        </>
    );
}

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import moment from 'moment';
import { Box, Grid, Paper } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SttValidatedForm from '../../../sporttia/SttValidatedForm';
import { useLoader, useTranslations } from '../../../lib/hooks';
import useGolfService from '../../../services/GolfService';
import { SPORTCENTER_GOLF_COURSE_SCHEDULE } from '../../../lib/queryKeys';
import { SttFullDialog } from '../../../sporttia/dialogs/SttFullDialog';
import GolfCourseScheduleRateDialog from './GolfCourseScheduleRateDialog';
import { formatPrice } from '../../../lib/utils';
import { SttList } from '../../../sporttia/lists/SttList';

const useStyles = makeStyles({
    genericBorder: {
        borderColor: '#C4C4C4',
    },
    borderRounded: {
        borderRadius: '5px',
        minHeight: '150px',
    },
});

/**
 * Dialogue to modify, update or delete schedules of a golf course.
 * @param id Golf course schedule ID.
 * @param golfCourseId Golf course ID.
 * @param onClose Action taken when closing the dialog.
 * @param onCreate Action taken when creating the subscription.
 * @param onUpdate Action taken when updating the subscription.
 * @param onDelete Action taken when removing subscription.
 * @returns {JSX.Element}
 */
function GolfCourseScheduleDialog({
    id,
    golfCourseId,
    onClose,
    onCreate,
    onUpdate,
    onDelete,
}) {
    const classes = useStyles();
    const { translate } = useTranslations();
    const form = useForm();
    const queryClient = useQueryClient();
    const golfService = useGolfService();
    const [golfCourseScheduleId, setGolfCourseScheduleId] = useState(null);
    const [selectedGolfCourseRate, setSelectedGolfCourseRate] = useState(null);
    const golfCourseScheduleQuery = useQuery(
        [SPORTCENTER_GOLF_COURSE_SCHEDULE, { id: golfCourseScheduleId }],
        () => golfService.getGolfCourseFree(golfCourseScheduleId),
        {
            enabled:
                !!golfCourseScheduleId && golfCourseScheduleId !== 'create',
        },
    );
    const createMutation = useMutation(
        (params) =>
            golfService.createGolfCoursesFrees(params?.id, params?.params),
        {
            onSuccess: onCreate,
        },
    );
    const updateMutation = useMutation(
        (params) =>
            golfService.updateGolfCoursesFrees(params?.id, params?.params),
        {
            onSuccess: onUpdate,
        },
    );
    const deleteMutation = useMutation(
        (params) => golfService.removeGolfCoursesFrees(params?.id),
        {
            onSuccess: onDelete,
        },
    );
    const deleteRateMutation = useMutation(
        (params) => golfService.removeGolfCoursesRates(params?.id),
        {
            onSuccess: () =>
                queryClient.invalidateQueries(SPORTCENTER_GOLF_COURSE_SCHEDULE),
        },
    );
    const [, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
        createMutation.status,
        deleteRateMutation.status,
    ]);

    /**
     * Method executed when the user clicks on save or create golf course schedule.
     */
    const mutateGolfCourseSchedule = (formData) => {
        const formatedFormData = { ...formData };

        if (formatedFormData?.status) {
            formatedFormData.status = 'ACTIVE';
        } else {
            formatedFormData.status = 'INACTIVE';
        }

        if (golfCourseScheduleQuery?.data?.golfScheduleFree?.id) {
            updateMutation.mutate({
                id: golfCourseScheduleQuery?.data?.golfScheduleFree?.id,
                params: formatedFormData,
            });
        } else {
            createMutation.mutate({
                id: golfCourseId,
                params: formatedFormData,
            });
        }
    };

    /**
     * When the data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (golfCourseScheduleQuery?.data?.golfScheduleFree?.id) {
            form.reset({
                dateini:
                    golfCourseScheduleQuery?.data?.golfScheduleFree?.dateini,
                dateend:
                    golfCourseScheduleQuery?.data?.golfScheduleFree?.dateend,
                timeini:
                    golfCourseScheduleQuery?.data?.golfScheduleFree?.timeini,
                timeend:
                    golfCourseScheduleQuery?.data?.golfScheduleFree?.timeend,
                months: golfCourseScheduleQuery?.data?.golfScheduleFree?.months,
                weekdays:
                    golfCourseScheduleQuery?.data?.golfScheduleFree?.weekdays,
                status:
                    golfCourseScheduleQuery?.data?.golfScheduleFree?.status ===
                    'ACTIVE',
            });
        } else {
            form.reset({
                dateini: moment(
                    new Date(new Date().getFullYear(), 0, 1),
                ).format('YYYY-MM-DD'),
                dateend: moment(
                    new Date(new Date().getFullYear(), 11, 31),
                ).format('YYYY-MM-DD'),
                timeini: moment(
                    new Date(new Date().getFullYear(), 0, 1, 8, 0),
                ).format('H:mm'),
                timeend: moment(
                    new Date(new Date().getFullYear(), 11, 31, 22, 0),
                ).format('H:mm'),
                months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                weekdays: [1, 2, 3, 4, 5, 6, 0],
                status: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [golfCourseScheduleQuery?.data?.golfScheduleFree]);

    /**
     * Update golf course schedule id if id prop was changed.
     */
    useEffect(() => {
        setGolfCourseScheduleId(id);
    }, [id]);

    return (
        <SttFullDialog
            open={id !== null}
            onClose={onClose}
            title={
                // eslint-disable-next-line no-nested-ternary
                golfCourseScheduleQuery?.isLoading
                    ? ''
                    : golfCourseScheduleQuery?.data?.golfScheduleFree?.id
                      ? translate('EditFreeSchedule')
                      : translate('CreateFreeSchedule')
            }
            bottomButtons={[
                {
                    type: 'save',
                    onClick: form.handleSubmit((formData) =>
                        mutateGolfCourseSchedule(formData),
                    ),
                },
            ]}
        >
            <Paper>
                <Box p={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            {loader}
                            <SttValidatedForm
                                form={form}
                                loadingData={
                                    golfCourseScheduleQuery?.status ===
                                    'loading'
                                }
                                fields={[
                                    {
                                        type: 'date',
                                        name: 'dateini',
                                        caption: translate('DateIni'),
                                        md: 6,
                                    },
                                    {
                                        type: 'date',
                                        name: 'dateend',
                                        caption: translate('DateEnd'),
                                        md: 6,
                                    },
                                    {
                                        type: 'time',
                                        name: 'timeini',
                                        caption: translate('TimeIni'),
                                        md: 6,
                                    },
                                    {
                                        type: 'time',
                                        name: 'timeend',
                                        caption: translate('TimeEnd'),
                                        md: 6,
                                    },
                                    {
                                        type: 'months',
                                        name: 'months',
                                    },
                                    {
                                        type: 'weekdays',
                                        name: 'weekdays',
                                    },
                                    {
                                        type: 'check',
                                        name: 'status',
                                        caption: translate('Active'),
                                    },
                                ]}
                            />
                        </Grid>
                        {golfCourseScheduleQuery?.data?.golfScheduleFree && (
                            <Grid item xs={12} md={6}>
                                <Box
                                    className={`${classes.genericBorder} ${classes.borderRounded}`}
                                    border={1}
                                >
                                    <SttList
                                        title={translate('Rates')}
                                        onDelete={(rate) =>
                                            deleteRateMutation.mutate({
                                                id: rate?.id,
                                            })
                                        }
                                        confirmationDelete
                                        onAdd={() =>
                                            setSelectedGolfCourseRate({})
                                        }
                                        onSelect={(rate) =>
                                            setSelectedGolfCourseRate(rate)
                                        }
                                        data={golfCourseScheduleQuery?.data?.golfScheduleFree?.rates?.rows.map(
                                            (rate) => ({
                                                caption: (
                                                    <Box
                                                        display="flex"
                                                        justifyContent="space-between"
                                                    >
                                                        <div>{`${
                                                            rate?.name
                                                        } - ${formatPrice(
                                                            rate?.price,
                                                        )} ${
                                                            rate?.group
                                                                ? `(${translate(
                                                                      'Group',
                                                                  )}: ${
                                                                      rate
                                                                          ?.group
                                                                          ?.name
                                                                  })`
                                                                : ''
                                                        }`}</div>
                                                    </Box>
                                                ),
                                                objToSelect: rate,
                                            }),
                                        )}
                                    />
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Paper>

            {/* Rate dialog. */}
            <GolfCourseScheduleRateDialog
                golfScheduleId={
                    golfCourseScheduleQuery?.data?.golfScheduleFree?.id
                }
                rate={selectedGolfCourseRate}
                onClose={() => setSelectedGolfCourseRate(null)}
                onCreate={() => {
                    queryClient.invalidateQueries(
                        SPORTCENTER_GOLF_COURSE_SCHEDULE,
                    );
                    setSelectedGolfCourseRate(null);
                }}
                onUpdate={() => {
                    queryClient.invalidateQueries(
                        SPORTCENTER_GOLF_COURSE_SCHEDULE,
                    );
                    setSelectedGolfCourseRate(null);
                }}
                onDelete={() => {
                    queryClient.invalidateQueries(
                        SPORTCENTER_GOLF_COURSE_SCHEDULE,
                    );
                    setSelectedGolfCourseRate(null);
                }}
            />
        </SttFullDialog>
    );
}

export default GolfCourseScheduleDialog;

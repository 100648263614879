import React, {
    useState,
    useEffect,
    useReducer,
    useContext,
    useRef,
    MutableRefObject,
} from 'react';
import { useQueryClient } from 'react-query';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../sporttia/all';
import SttLoadingBar from '../sporttia/SttLoadingBar';
import DialogComponent from '../components/dialogs/ConfirmDialog';
import SttFullPageSpinner from '../sporttia/spinners/SttFullPageSpinner';
import {
    checkObjEqual,
    getErrorMessage,
    getQueryStrings,
    secondsToFormatTime,
} from './utils';
import { SocketMessage, isErrorResponse, isObject } from '../types/common';
import { HttpVerb, MigrationApis } from '../types/api/utils';
import Migration from '../types/models/Migration';

/**
 * useCrudFuncs: expose context's API function as wrapper functions (Get, Post, Put, Delete) that return promises
 * autoMessages - automatically show success messages like "Created", "Saved" etc.
 * @deprecated
 */
export function useCrudFuncs(autoMessages = false) {
    const {
        api,
        showMessage,
        t: translate,
        constants,
    } = useContext(AppContext)!;

    /**
     * @deprecated
     */
    function Get(resource: string, params?: Record<string, unknown>) {
        return new Promise<unknown>((resolve, reject) => {
            api('GET', resource, {
                params,
                success: resolve,
                error: (response) => {
                    if (
                        response !== constants.apiLock &&
                        isErrorResponse(response) &&
                        autoMessages
                    ) {
                        showMessage('E', response.error.msg);
                    }
                    reject(response);
                },
            });
        });
    }

    /**
     * @deprecated
     */
    function Post(
        resource: string,
        params?: Record<string, unknown>,
        confirmation = false,
    ) {
        return new Promise<unknown>((resolve, reject) => {
            api('POST', resource, {
                confirmation,
                params,
                success: (response) => {
                    if (autoMessages) {
                        showMessage('S', translate('Created'));
                    }
                    resolve(response);
                },
                error: (response) => {
                    if (
                        response !== constants.apiLock &&
                        isErrorResponse(response) &&
                        autoMessages
                    ) {
                        showMessage('E', response.error.msg);
                    }
                    reject(response);
                },
            });
        });
    }

    /**
     * @deprecated
     */
    function Put(
        resource: string,
        params?: Record<string, unknown>,
        confirmation = false,
    ) {
        return new Promise<unknown>((resolve, reject) => {
            api('PUT', resource, {
                confirmation,
                params,
                success: (response) => {
                    if (autoMessages) {
                        showMessage('S', translate('Saved'));
                    }
                    resolve(response);
                },
                error: (response) => {
                    if (
                        response !== constants.apiLock &&
                        isErrorResponse(response) &&
                        autoMessages
                    ) {
                        showMessage('E', response.error.msg);
                    }
                    reject(response);
                },
            });
        });
    }

    /**
     * @deprecated
     */
    function Delete(
        resource: string,
        params?: Record<string, unknown>,
        confirmation = true,
    ) {
        return new Promise<unknown>((resolve, reject) => {
            api('DELETE', resource, {
                confirmation,
                params,
                success: (response) => {
                    if (autoMessages) {
                        showMessage('S', translate('Deleted'));
                    }
                    resolve(response);
                },
                error: (response) => {
                    if (
                        response !== constants.apiLock &&
                        isErrorResponse(response) &&
                        autoMessages
                    ) {
                        showMessage('E', response.error.msg);
                    }
                    reject(response);
                },
            });
        });
    }

    return [Get, Post, Put, Delete] as const;
}

/**
 * Note that this component handles the value as int values instead of bool even if bools are passed to it, for ex. calling useToggle(true)
 * This is because the API always accepts trash:1/0 but not always accepts trash:true/false; and also because 1 and 0 are truthy and falsy in Javascript anyway.
 */
export function useToggle(
    callback: () => void | undefined,
    initialState: boolean,
) {
    function reducer(state: { ready: boolean; value: number }, action: number) {
        if (action === +!state.value) {
            return { value: action, ready: true };
        }
        return state;
    }

    const [state, setState] = useReducer(reducer, {
        ready: false,
        value: +(initialState || false),
    });

    useEffect(() => {
        if (state.ready && typeof callback === 'function') {
            callback();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.value]);

    function toggleState(force?: boolean) {
        if (force !== undefined) {
            setState(+force);
        } else {
            setState(+!!state.value);
        }
    }

    return [Boolean(state.value), toggleState];
}

/**
 * Hook to automate tuple updating in objects like {name1: value1, name2: value2}
 */
export function useTuples<T extends Record<string, unknown>>(initialObject: T) {
    const [tuples, setTuples] = useState<T>(initialObject);

    function setTuple({ name, value }: { name: keyof T; value: T[keyof T] }) {
        setTuples({ ...tuples, [name]: value });
    }

    return [tuples, setTuple] as const;
}

/**
 * Hook to automate the state of an object that has a direct representation in the back end.
 * @deprecated
 */
export function useCrud<T extends Record<string, unknown>>(
    initialState: T,
    autoMessages = true,
) {
    const [item, setItem] = useState<T>(initialState);

    const [Get, Post, Put, Delete] = useCrudFuncs(autoMessages);

    function setProperty({
        name,
        value,
    }: {
        name: keyof T;
        value: T[keyof T];
    }) {
        setItem({ ...item, [name]: value });
    }

    return [item, setItem, setProperty, Get, Post, Put, Delete] as const;
}

/**
 * Hook para consulta la API de Sporttia.
 * @param knownMigrationApis Endpoint que estan migrados a V7.
 * @param additionalOptions Opciones adicionales.
 * @returns
 */
export function useAPI(
    knownMigrationApis: MigrationApis | null = null,
    additionalOptions?: {
        version?: string;
        [key: string]: unknown;
    },
) {
    const [apiLocks, setApiLocks] = useState<string[]>([]);
    const [migrationEndpoints, setMigrationEndpoints] =
        useState(knownMigrationApis); // null required for proper initialization
    const apiCache: Record<string, unknown> = {};
    const { apiEnv } = window.runtimeConfig;

    /**
     * Formatea un array de endpoints de la migración desde el formato [{id, method, endpoint, active, createdAt, updatedAt}]
     * al formato {endpoint1: [method1, method2, method3], endpoint2: [method1, method2, method3]}. Básicamente un objeto en
     * el que la clave primaria es el path/endpoint al que se le asigna un array con los métodos que estan disponibles para
     * ese endpoint.
     * @param {MigrationEndpoints} endpoints - El objeto que contiene la información de las migraciones de EPS.
     * @returns Objeto formateado con las claves de endpoint y valores de método.
     */
    function formatMigrationsEps(endpoints: Migration[]) {
        return endpoints.reduce(
            (formatedMigrations: Record<string, string[]>, endpoint) => {
                const { endpoint: e, method } = endpoint;
                const parsedEndpoint = e
                    .replaceAll(/\{\w+\}/gi, '{param}')
                    .replace('/v7', '');

                const newFormatedMigrations = { ...formatedMigrations };

                newFormatedMigrations[parsedEndpoint] =
                    newFormatedMigrations[parsedEndpoint] || [];
                newFormatedMigrations[parsedEndpoint]!.push(method);

                return newFormatedMigrations;
            },
            {},
        );
    }

    /**
     * Función para setear los endpoints desde fuera del hook.
     * @param paths Array con la respuesta de la api de GET /migration-endpoints.
     */
    function setMigrationApis(paths: Migration[]) {
        const formatedMigrationEps = formatMigrationsEps(paths);

        setMigrationEndpoints(formatedMigrationEps);
    }

    /**
     * Obtiene la versión del endpoint dado los endpoints que tenemos migrados a V7.
     * @param method Método HTTP.
     * @param resource Path a consultar.
     * @returns
     */
    function getEPVersion(method: HttpVerb, resource: string) {
        // If this resource's version has already been determined, simply return it.
        if (apiCache[`${method}|${resource}`]) {
            return apiCache[`${method}|${resource}`]!;
        }

        // Se setea por defecto la versión a V6 a no ser que se haya pasado como campo adicional.
        let version = (additionalOptions && additionalOptions.version) || 'v6';

        // Check against migration apis in the shape ~/stuff.stuff (like 1234.jpg or ticket.pdf)
        if (migrationEndpoints && Object.keys(migrationEndpoints).length > 0) {
            // Se pasa un endpoint que se consulta al formato generico. Un ejemplo:
            // - /cities/1234 ---> /cities/{param}
            // - /cities?page=12&&rows=20 ---> /cities
            const generalization = resource
                .replace(/\/[\d]+(\.[\w\d]{3,4})?/gi, '/{param}$1')
                .replace(/\/[\d]+/, '/{param}')
                .replace(/(\?.*)|(#.*)/g, '');

            // Se busca si la el path formateado existe en el array de "migrationEndpoints".
            const endPointName = Object.keys(migrationEndpoints).includes(
                generalization,
            )
                ? generalization
                : false;

            // Si antes se ha encontrado un endpoint ya migrado entonces se comprueba que el método
            // http con el consultamos también existe y se devuelve la nueva versión (v7).
            if (endPointName) {
                const endPoint = migrationEndpoints[endPointName];

                if (endPoint && endPoint.includes(method)) {
                    version = 'v7';
                }
            }
        }

        // Aquí básicamente nos saltamos la comprobación de si un path existe en el objeto de endpoints
        // migrados ya que si el endpoint que se pide es "/migration-endpoints" solo existe en v7 y si
        // no lo tenemos activado desde el front no vamos a poder entrar en la vista de cargarlos porque
        // no existe en v6. En resument, este endpoint es unico y propio de v7.
        const result = resource.includes('/migration-endpoints')
            ? 'v7'
            : version;

        // Cache for repeated use (mutation because React shenanigans)
        apiCache[`${method}|${resource}`] = result;

        return result;
    }

    /**
    * General request method that pretty much does everything on its own
        Returns a Promise that resolves to:
            The response JSON for 200 OK
            {error : {msg: "<error_msg>", log: "<log_id>"}} for 401, 403, 404 and 500
            {errors: [{key: ["<error_msg>"...]}...], log: "<log_id>"}} for 400
    * @param method Metodo HTTP.
    * @param resource Endpoint a consultar.
    * @param params Parámetros de consulta.
    * @returns
    */
    function request<T>(
        method: HttpVerb,
        resource: string,
        params?: Record<string, unknown>,
    ) {
        const apiIndex = method + resource + JSON.stringify(params);

        if (method !== 'GET') {
            if (apiLocks.includes(apiIndex)) {
                // locked API
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('LOCK'); // Aquí lamentablemente no es fiable acceder a cxt.constants
            }

            setApiLocks(apiLocks.concat([apiIndex]));
        }

        const token = localStorage.getItem('token');

        const METHOD = method.toUpperCase() as HttpVerb;

        const version = getEPVersion(METHOD, resource);

        const headers = {
            Accept: 'application/json',
            'Content-Type':
                version === 'v6'
                    ? 'application/x-www-form-urlencoded'
                    : 'application/json',
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
        };

        let myResource = resource;

        // concat params into the querystring, but only for params which aren't undefined or null (to mimic previous behavior)
        if (METHOD === 'GET' && params) {
            if (Object.keys(params).length > 0) {
                // extract non-null non-undefined params from the provided ones
                const myParams = Object.keys(params).reduce(
                    (result, param) =>
                        params[param] !== undefined && params[param] !== null
                            ? {
                                  ...result,
                                  [param]: params[param],
                              }
                            : result,
                    {},
                );

                // contact the querystring of the resulting params
                myResource += Object.keys(myParams).reduce(
                    (result, param, i) =>
                        `${result + (i > 0 ? '&' : '?') + param}=${
                            params[param]
                        }`,
                    '',
                );
            }
        }

        const uri = `${apiEnv.url}/${version}${
            myResource.charAt(0) === '/' ? myResource : `/${myResource}`
        }`;

        return new Promise<T>((resolve, reject) => {
            // For POST, PUT and DELETE: append params object as request body
            fetch(uri, {
                headers,
                method,
                cache: 'no-cache',
                redirect: 'follow',
                ...(METHOD === 'POST' || METHOD === 'PUT' || METHOD === 'DELETE'
                    ? {
                          // Add params to body in these cases
                          body: JSON.stringify(params),
                      }
                    : {}),
            })
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        try {
                            // One of these can still be a logical error; for example: wrong password, inssuficent funds, etc... and also every non-500 error from v6 lol
                            // response.json() does not pre-check if the body text is empty. Added this to filter the content of the body and avoid runtime errors.
                            response.text().then((text) => {
                                if (text.length > 0) {
                                    const tentativeResponse = JSON.parse(
                                        text,
                                    ) as T;

                                    // Si la respuesta es 200 o 201, pero tiene la forma {error: {msg}}, lo tratamos como un error y rechazamos
                                    if (isErrorResponse(tentativeResponse)) {
                                        reject(tentativeResponse);
                                    } else {
                                        resolve(tentativeResponse);
                                    }
                                } else {
                                    resolve('' as T); // Resolve to success with empty value string for those endpoints that dont't need to return anything
                                }
                            });
                        } catch (error) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            reject({
                                error: {
                                    msg: error,
                                },
                            });
                        }
                    } else if (response.status === 400) {
                        response.json().then((result) => {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            reject({
                                ...result,
                                log: response.headers.get('X-Request-Id'),
                            });
                        });
                    } else {
                        // These responses include both the fake 404 from v6 and the status codes 401, 403, 404 and 500 from v7 ('x-stt-req' doesn't exist in the fake 404)
                        response
                            .json()
                            .then((result) => {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                reject({
                                    error: {
                                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                        msg:
                                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                            result.message ||
                                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                            (result.error && result.error.msg),
                                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                                        errors: result?.error?.errors,
                                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                        log:
                                            (response.headers.get(
                                                'X-Request-Id',
                                            ) &&
                                                response.headers.get(
                                                    'X-Request-Id',
                                                )) ||
                                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                            (result.log && result.log.id),
                                    },
                                });
                            })
                            .catch((err) => {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                reject({
                                    error: {
                                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                        msg:
                                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                            err.message ||
                                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                            (err.error && err.error.msg),
                                    },
                                });
                            });
                    }
                })
                .catch((err) => {
                    // Not even a status code
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject({
                        error: {
                            msg: 'Error desconocido',
                            log: JSON.stringify(err),
                        },
                    });
                })
                .finally(() => {
                    if (method !== 'GET') {
                        setApiLocks(
                            apiLocks.filter((lock) => lock !== apiIndex),
                        );
                    }
                });
        });
    }

    /**
     * Obtiene el path completo de un endpoint (dominio + version + path).
     * @param resource Path a consultar.
     * @returns
     */
    function getResource(resource: string, method: HttpVerb = 'GET') {
        const version = getEPVersion(method, resource);
        return `${apiEnv.url}/${version}${resource}`;
    }

    return [
        request,
        migrationEndpoints,
        setMigrationApis,
        getResource,
    ] as const;
}

/**
 * Hook que devuelve los valores de los parámetros de consulta de la URL actual.
 */
export function useQuery() {
    const { search } = useLocation();

    return getQueryStrings(search, {
        parseNumbers: true,
        parseBooleans: true,
    });
}

/**
 * Get user geolocation HOOK. Credits: https://github.com/trekhleb/use-position
 */
const defaultSettings = {
    enableHighAccuracy: false,
    timeout: Infinity,
    maximumAge: 0,
};

export function usePosition(watch = false, settings = defaultSettings) {
    const [position, setPosition] = useState<{
        latitude?: number;
        longitude?: number;
        accuracy?: number;
        speed?: number | null;
        timestamp?: number;
    }>({});
    const [error, setError] = useState<string | null>(null);

    const onChange = ({ coords, timestamp }: GeolocationPosition) => {
        setPosition({
            latitude: coords.latitude,
            longitude: coords.longitude,
            accuracy: coords.accuracy,
            speed: coords.speed,
            timestamp,
        });
    };

    const onError = (err: GeolocationPositionError) => {
        setError(err.message);
    };

    useEffect(() => {
        if (!navigator || !navigator.geolocation) {
            setError('Geolocation is not supported');
            return;
        }

        let watcher: number | null = null;
        if (watch) {
            watcher = navigator.geolocation.watchPosition(
                onChange,
                onError,
                settings,
            );
        } else {
            navigator.geolocation.getCurrentPosition(
                onChange,
                onError,
                settings,
            );
        }

        return () => {
            if (watcher) {
                navigator.geolocation.clearWatch(watcher);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings.enableHighAccuracy, settings.timeout, settings.maximumAge]);

    return { ...position, error };
}

/**
 * useLoadingBar: exposes a loading bar component along with its show and hide functions. For use with a case by case basis.
 * @param bool initialVisibility
 * @returns [function show, function hide, ReactNode SttLoadingBar]
 */
export function useLoadingBar(initialVisibility = false) {
    const [visible, setVisible] = useState(initialVisibility);

    function show() {
        setVisible(true);
    }

    function hide() {
        setVisible(false);
    }

    return [show, hide, <SttLoadingBar visible={visible} />] as const;
}

/**
 * Opens a confirmation dialog with the parameters specified in 'openDialog'.
 * Returns [openingFunction, closingFunction, renderedDialogComponent]
 */
export function useConfirmDialog() {
    const [state, setState] = useState<{
        open: boolean;
        title: string | null;
        content: string | null;
        onConfirm: (() => void) | null;
        onCancel: (() => void) | null;
    }>({
        open: false,
        title: null,
        content: null,
        onConfirm: null,
        onCancel: null,
    });

    function closeDialog() {
        setState({ ...state, open: false });
    }

    function openDialog({
        title = null,
        content = null,
        onConfirm,
        onCancel,
    }: {
        title?: string | null;
        content?: string | null;
        onConfirm?: (() => void) | null;
        onCancel?: (() => void) | null;
    }) {
        setState({
            open: true,
            title,
            content,
            onConfirm: () => {
                if (onConfirm) {
                    onConfirm();
                }
                closeDialog();
            },
            onCancel: () => {
                if (onCancel) {
                    onCancel();
                }
                closeDialog();
            },
        });
    }

    return [
        openDialog,
        closeDialog,
        <DialogComponent
            open={state.open}
            title={state.title}
            content={state.content}
            onCancel={state.onCancel}
            onConfirm={state.onConfirm}
        />,
    ] as const;
}

/**
 * Hook to make a load spinner appear, generally used for API calls (using status value returned by react-query).
 */
export function useLoader(statusList: string[] = []) {
    const [loading, setLoading] = useState(statusList.includes('loading'));

    useEffect(() => {
        setLoading(statusList.includes('loading'));
    }, [statusList]);

    return [loading, loading ? <SttFullPageSpinner /> : null] as const;
}

/**
 * Hook to set header title.
 */
export function usePageHeader(titleTranslationKey = '') {
    const cxt = useContext(AppContext)!;

    function setPageTitle(translationKey: string) {
        cxt.setHeader({ title: cxt.t(translationKey) });
    }

    useEffect(() => {
        cxt.setHeader({ title: cxt.t(titleTranslationKey) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cxt.header?.title, titleTranslationKey]);

    return [setPageTitle] as const;
}

/**
 * Hook for filtering SttCachedTable so that when modifying a filter the request is always made regardless of
 * whether the filter has been modified or not. It was requested because it was necessary to request data when
 * clicking on the filters when it was not changed.
 */
export function useQueryFilters<TFilters extends Record<string, unknown>>(
    filtersValue: TFilters,
    queryKey?: string,
) {
    const queryClient = useQueryClient();
    const [filters, setFiltersValues] = useState(filtersValue);

    /**
     * Update the filters in case they are different, otherwise invalidate the
     * cache so that SttCachedTable is updated.
     * @param updatedFilters New filters value.
     */
    const setFilters = (updatedFilters: TFilters) => {
        if (checkObjEqual(updatedFilters, filters)) {
            queryClient.invalidateQueries(queryKey);
        } else {
            setFiltersValues(updatedFilters);
        }
    };

    return [filters, setFilters] as const;
}
/**
 * Hook for the use of translations.
 */
export function useTranslations() {
    const ctx = useContext(AppContext);

    if (!ctx || !ctx.t) {
        throw new Error(
            'useTranslationState must be used within the AppProvider',
        );
    }

    return { translate: ctx.t };
}

/**
 * Hook for interaction with files. Returns the methods for opening, downloading and uploading files.
 */
export function useInteractionsFiles() {
    const {
        api,
        getResource,
        showMessage,
        t: translate,
    } = useContext(AppContext)!;
    const userToken = localStorage.getItem('token') || null;

    /**
     * Requests a file using the API.
     */
    const requestFile = (
        url: string,
        // eslint-disable-next-line @typescript-eslint/default-param-last
        type = 'text/plain',
        params: Record<string, unknown> | null,
        method = 'GET',
        bodyParams = null,
    ) =>
        new Promise<string>((resolve, reject) => {
            const req = new XMLHttpRequest();

            // api migrations version (new)
            const serializedParams = params
                ? Object.keys(params).reduce(
                      (res, key, i) =>
                          `${res}${i === 0 ? '?' : '&'}${key}=${params[key]}`,
                      '',
                  )
                : '';

            let fileUrl = url + serializedParams;

            if (fileUrl.indexOf('https') === -1) {
                fileUrl = getResource(fileUrl, method as HttpVerb);
            }

            req.open(method, fileUrl, true);

            if (userToken) {
                req.setRequestHeader('Authorization', `Bearer ${userToken}`);
            }

            req.onreadystatechange = function handleStateChange() {
                if (req.readyState === 4) {
                    if (req.status !== 200 && req.responseText !== '') {
                        if (req.status === 404 || req.status === 400)
                            showMessage(
                                'E',
                                getErrorMessage(
                                    JSON.parse(req.response as string),
                                ),
                            );
                        reject(JSON.parse(req.response as string));
                    }
                } else if (req.readyState === 2) {
                    if (req.status === 200) {
                        req.responseType = 'blob';
                    } else {
                        req.responseType = 'text';
                    }
                }
            };

            req.onload = function handleLoad() {
                // En v6, los CSV se envían en Windows-1252, mientras que en v7 en UTF-8.
                // Para que Excel interprete los CSV de v7 correctamente, se agrega "charset" al type.
                let blobType = type;
                if (type === 'text/csv' && fileUrl.includes('/v7/')) {
                    blobType = 'text/csv;charset=utf-8';
                }

                const blob = new Blob([req.response], { type: blobType });
                const data = window.URL.createObjectURL(blob);

                // This data url is our result
                resolve(data);

                // revoke the object url after a short delay
                setTimeout(() => {
                    window.URL.revokeObjectURL(data);
                }, 100);
            };

            req.onerror = function handleError() {
                reject(req.response);
            };

            req.ontimeout = function handleTimeout() {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject('Request timeout');
            };

            if (method === 'POST' && bodyParams) {
                req.setRequestHeader('Content-Type', 'application/json');
                const body = JSON.stringify(bodyParams);
                req.send(body);
            } else {
                req.send();
            }
        });

    /**
     * Open file invoked by API. Similar to downloadFile, except this one opens the file directly in the browser
     * @param url Request URL.
     * @param type File type.
     * @param params Object with params.
     * @param downloadFilename Name of the file to be downloaded.
     */
    const openFile = (
        url: string,
        // eslint-disable-next-line @typescript-eslint/default-param-last
        type = 'text/plain',
        params: Record<string, unknown> | null,
        downloadFilename = translate('DownloadNoun'),
        method = 'GET',
        bodyParams = null,
    ) => {
        requestFile(url, type, params, method, bodyParams).then((dataUrl) => {
            // Create link with target '_new' and click it
            const link = document.createElement('a');
            link.href = dataUrl;
            link.target = '_new';

            // On Chromium (Chrome, Edge) the browser cannot download a file opened via a blob url, therefore, hack:
            if (window.navigator.userAgent.includes('Chrome')) {
                link.download = downloadFilename; // fall backs to 'Download.<extension>'
            }

            link.click();
        });
    };

    /**
     * Download file invoked by API. Opens a possibly authorized request to a file and then opens it as
     * if the user had clicked a direct link.
     * @param url Relative url of the file to download, for example: /scs/1245/payments.pdf.
     * @param type Mime type of the file, for example 'application/pdf'.
     * @param params Like unknown other API call, {key1: values1, key2: value2}.
     * @param downloadFilename Name of the file to be downloaded.
     * @param method Method to execute the request.
     * @param bodyParams If method is POST, the body of the request
     * @param onError If exists, onError callback
     * @param onSuccess If exists, onSuccess callback
     */
    const downloadFile = (
        url: string,
        // eslint-disable-next-line @typescript-eslint/default-param-last
        type = 'text/plain',
        params: Record<string, unknown>,
        downloadFilename = translate('DownloadNoun'),
        method = 'GET',
        bodyParams = null,
        onError?: (response: string) => void,
        onSuccess?: () => void,
    ) => {
        requestFile(url, type, params, method, bodyParams)
            .then((dataUrl) => {
                // Create a "download link" and click it
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = downloadFilename;
                link.click();

                if (onSuccess) {
                    onSuccess();
                }
            })
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            .catch((error: string) => {
                if (onError) onError(error);
                else showMessage('E', getErrorMessage(error));
            });
    };

    /**
     * Subir fichero usando content-type: multipart/form-data
     * @param metaData Object with values: {name, type, format}
     * @param file The file like from an <input type="file">
     * @returns {Promise<unknown>}
     */
    const uploadFile = (
        metaData: { value: string; type: string; format: string },
        file: string | Blob,
    ) => {
        const { apiEnv } = window.runtimeConfig;

        return new Promise((resolve, reject) => {
            const formData = new FormData();

            (Object.keys(metaData) as Array<keyof typeof metaData>).forEach(
                (key) => {
                    formData.append(key, metaData[key]);
                },
            );

            formData.append('file', file);

            // No podemos usar api() porque no soporta headers custom (por dentro está el content-type hardcodeado etc)
            axios({
                url: `${apiEnv.url}/v7/uploads`, // solo existe en v7
                method: 'POST',
                data: formData,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                    Authorization: userToken ? `Bearer ${userToken}` : '',
                },
            })
                .then((response: { data: unknown }) => response.data)
                .then((response) => {
                    if (isObject(response) && 'error' in response) {
                        reject(response.error);
                    } else {
                        resolve(response); // esto debe ser {file}
                    }
                })
                .catch((error) => {
                    showMessage('E', 'Unknown error on Sporttia Server');
                    // eslint-disable-next-line no-console
                    console.error(error);
                    reject(error);
                });
        });
    };

    /**
     * Subir fichero usando la api de subida de V6 en dos pasos con application/x-www-form-urlencoded.
     * @param metaData
     * @param fileContent
     * @returns {Promise<unknown>}
     */
    const uploadFileLegacyV6 = (
        metaData: Record<string, unknown>,
        fileContent: unknown,
    ): Promise<unknown> =>
        new Promise((resolve, reject) => {
            api('POST', '/files', {
                params: metaData,
                success: (data) => {
                    if (isObject(data) && data.file) {
                        // After creating file, upload the content
                        axios({
                            method: 'POST',
                            url: `${data.fileRowUploadingUrl}`,
                            params: null,
                            data: fileContent,
                            headers: {
                                'Content-Type':
                                    'application/x-www-form-urlencoded',
                                Authorization: userToken
                                    ? `Bearer ${userToken}`
                                    : '',
                            },
                        })
                            .then((response) => {
                                if (isErrorResponse(response)) {
                                    reject(response.error);
                                } else {
                                    resolve({ file: data.file, response });
                                }
                            })
                            .catch((error) => {
                                showMessage(
                                    'E',
                                    'Unknown error on Sporttia Server',
                                );
                                // eslint-disable-next-line no-console
                                console.error(error);
                                reject(error);
                            });
                    } else {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        reject('Error: no file in resp :(');
                    }
                },
            });
        });

    return { openFile, downloadFile, uploadFile, uploadFileLegacyV6 } as const;
}

/**
 * Hook return a countdown timer.
 */
export function useCountDownTimer({
    initialSeconds = 60,
    pause = false,
    onTimeOut = null,
}: {
    initialSeconds?: number;
    pause?: boolean;
    onTimeOut: (() => void) | null;
}) {
    const [seconds, setSeconds] = useState(initialSeconds);
    const [formatTime, setFormatTime] = useState(
        secondsToFormatTime(initialSeconds),
    );
    const [timeOut, setTimeOut] = useState(false);
    const [pauseTimer, setPauseTimer] = useState(pause);
    const toggleTimer = () => setPauseTimer((t) => !t);

    useEffect(() => {
        if (seconds === 0) {
            setTimeOut(true);
            if (onTimeOut) {
                onTimeOut();
            }
        } else if (!pauseTimer) {
            const timeout = setTimeout(() => {
                const updatedSeconds = seconds - 1;
                setSeconds(updatedSeconds);
                setFormatTime(secondsToFormatTime(updatedSeconds));
            }, 1000);

            return () => {
                clearTimeout(timeout);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seconds, pauseTimer]);

    return { formatTime, timeOut, toggleTimer } as const;
}

/**
 * Return APP Environment.
 * @deprecated Utilizar config/constants
 */
export function useEnvironment() {
    let environment = 'PRO';
    if (window.runtimeConfig.apiEnv.environment === 'PRE') {
        environment = 'PRE';
    } else if (
        window.runtimeConfig.apiEnv.environment === 'DEV' ||
        process.env.NODE_ENV === 'development'
    ) {
        environment = 'DEV';
    }

    return environment;
}

/**
 * Hook to check if element is in the viewport.
 */
export function useOnViewport(
    ref: MutableRefObject<HTMLElement>,
    // eslint-disable-next-line @typescript-eslint/default-param-last
    rootMargin = '0px',
    onViewport: () => void,
) {
    // State and setter for storing whether element is visible
    const [isIntersecting, setIntersecting] = useState<boolean>(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Update our state when observer callback fires
                setIntersecting(!!entry && entry.isIntersecting);

                if (entry && entry.isIntersecting && onViewport) {
                    onViewport();
                }
            },
            {
                rootMargin,
            },
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            observer.unobserve(ref.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return isIntersecting;
}

/**
 * Hook para establecer una conexión mediante websocket contra el servidor de v7
 * Actualmente no es un procedimiento genérico para abrir sockets, sino que tiene funcionalidades muy específicas para
 * el sistema de notificaciones de accesos de usuarios a través de los dispositivos físicos (tornos y demás)
 */
export function useSocketConnection() {
    useContext(AppContext); // No se utilizaba el objeto ctx. No se a removido la llamada a la espera de comprobar si es necesario.
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [ready, setReady] = useState(false);
    const [receivedMessages, setReceivedMessages] = useState<SocketMessage[]>(
        [],
    );
    const [queuedDeletions, setQueuedDeletions] = useState<SocketMessage[]>([]);

    // calc a pseudo id for the message as <access type>:<mship id>
    const pseudoId = (message: SocketMessage) =>
        message ? `${message?.access?.type}:${message?.mship?.id}` : '';

    // eliminar un mensaje de los recibidos. Se identifican por tipo + mship.id
    const deleteMessage = (message: SocketMessage) => {
        const id = pseudoId(message);

        const found = receivedMessages.find((msg) => pseudoId(msg) === id);

        if (found) {
            const newMessages = receivedMessages.filter(
                (msg) => pseudoId(msg) !== id,
            );
            setReceivedMessages(newMessages);
        }
    };

    const closeSocket = (message = 'socket closed') => {
        if (socket) {
            socket.close(1000, message);
            setSocket(null);
        }
    };

    // Close socket on unload
    useEffect(() => {
        if (socket) {
            setReady(true);

            return () => {
                closeSocket();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    // Mensajes pendiente de borrado. Estoy hay que hacerlo así porque no interactúan bien los estados de react con
    // las funciones declaradas como callbacks de eventos (un absoluto sindiós, si valoras tu salud mental, huye).
    useEffect(() => {
        if (queuedDeletions.length > 0) {
            queuedDeletions.forEach(deleteMessage);
            setQueuedDeletions([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queuedDeletions.length]);

    // inserta un mensaje en la cola de borrado
    const queueMessageDeletion = (message: SocketMessage) => {
        setQueuedDeletions([...queuedDeletions, message]);
    };

    // enviar mensaje al socket (requiere auth)
    const sendMessage = (message: SocketMessage) => {
        if (socket) {
            socket.send(
                JSON.stringify({
                    ...message,
                    authorization: `Bearer ${localStorage.getItem('token')}`,
                }),
            );
        }
    };

    /**
     * Por algún extraño bug de React, la interacción entre socket.onmessage y los estados de react no funciona bien.
     * El callback asignado a socket.onmessage no es capaz de leer los cambios de estado en el componente.
     * Como solución asignamos el callback de nuevo cada vez que llegue un mensaje, para que desde la func. puedan obtenerse
     * correctamente el número de mensajes previos.
     * Nota: si crees que esto iría mejor dentro de openSocket(), estás equivocado. No pierdas el tiempo intentándolo.
     */
    useEffect(() => {
        if (ready) {
            socket!.onmessage = (event: { data: string }) => {
                const message = JSON.parse(event.data) as SocketMessage;

                const messages = [...receivedMessages, message];

                // Añadir a la cola de mensajes por borrar tras 10 secs.
                setTimeout(() => {
                    queueMessageDeletion(message);
                }, 10000);

                setReceivedMessages(messages);
            };
            setSocket(socket);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receivedMessages, ready]);

    /**
     * Func. principal para establecimiento de la comunicación.
     * socket.onmessage no obstante está declarada dentro de un efecto secundario que depende de receivedMessages (ver más arriba)
     */
    const openSocket = () => {
        try {
            const socketConf = window.runtimeConfig.socket;

            if (!socketConf) {
                throw new Error(
                    'Falta window.runtimeConf.socket. Añade "socket: {url: url_de_conexion}" ',
                );
            }

            const newSocket = new WebSocket(socketConf.url);

            // eslint-disable-next-line no-console
            console.log(`Opening socket connection to ${socketConf.url}`);

            // Initial authorization
            newSocket.onopen = () => {
                newSocket.send(
                    JSON.stringify({
                        authorization: `Bearer ${localStorage.getItem(
                            'token',
                        )}`,
                        data: {
                            deviceIds: localStorage.getItem('registeredDevices')
                                ? (JSON.parse(
                                      localStorage.getItem(
                                          'registeredDevices',
                                      )!,
                                  ) as unknown)
                                : [],
                        },
                    }),
                );
            };

            // Socket closed
            newSocket.onclose = function handleOnClose(event) {
                if (event.wasClean) {
                    // eslint-disable-next-line no-console
                    console.log(
                        `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`,
                    );
                } else {
                    // e.g. server process killed or network down
                    // event.code is usually 1006 in this case
                    // eslint-disable-next-line no-console
                    console.log('[close] Connection died');
                }
            };

            // Socket error
            newSocket.onerror = function handleOnError(error) {
                // eslint-disable-next-line no-console
                console.error(`[error] ${getErrorMessage(error)}`);
            };

            setSocket(newSocket);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    };

    return [
        openSocket,
        closeSocket,
        receivedMessages,
        sendMessage,
        deleteMessage,
    ] as const;
}

/**
 * @deprecated Utilizar DateBuilder
 * Mover a utils. No es necesario que sea un custom hook
 * Hook para eliminar partes de una fecha para casos donde solo se quiera mostrar bajo un formato concreto
 */
export function useFormatDate() {
    const removeSeconds = (date: string) => {
        if (date.split(':').length === 3)
            return date.slice(0, date.lastIndexOf(':'));
        return date;
    };

    return [removeSeconds] as const;
}

/**
 * Hook para evitar bug en SttCachedTable donde la gestion de estado de los filtros cambia innecesariamente
 */
export function usePrevious(value: unknown) {
    const ref = useRef<unknown>();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

/**
 * Devuelve una función de configuración que, según POSType (PAYCOMET, etc) permite inicializar la config pasandole la ip local del datáfono
 */
export function usePOSConfig(POSType: unknown) {
    const environment = useEnvironment();

    const setupConfig = (ip: unknown) => {
        let uri;

        let config;

        switch (POSType) {
            case 'PAYCOMET':
            default:
                uri = `https://${ip}:${environment === 'DEV' ? 3001 : 3011}`;
                // Configuración de endpoints
                config = {
                    init: {
                        method: 'POST',
                        uri: `${uri}/v1/transactions/init`,
                        params: () => {},
                        response: (input: {
                            resultCode: unknown;
                            resultMessage: unknown;
                        }) => ({
                            // eslint-disable-next-line eqeqeq
                            status: input.resultCode == 1000 ? 'OK' : 'ERROR',
                            message: input.resultMessage,
                        }),
                    },
                    transaction: {
                        method: 'POST',
                        uri: `${uri}/v1/transactions/payment`,
                        params: (input: {
                            amount: unknown;
                            idTpv: unknown;
                            concept: unknown;
                        }) => ({
                            amount: input.amount,
                            orderId: input.idTpv,
                            description: input.concept,
                        }),
                        response: (input: {
                            resultCode: unknown;
                            ticket: { Status: unknown };
                            resultMessage: unknown;
                        }) => ({
                            status:
                                // eslint-disable-next-line eqeqeq
                                input.resultCode == 0 &&
                                input.ticket &&
                                // eslint-disable-next-line eqeqeq
                                input.ticket?.Status == '0'
                                    ? 'OK'
                                    : 'ERROR',

                            message: input.resultMessage,
                            ticket: input.ticket,
                        }),
                    },
                    refund: {
                        method: 'POST',
                        uri: `${uri}/v1/transactions/refund`,
                        params: (input: {
                            amount: unknown;
                            idTpv: unknown;
                            concept: unknown;
                            idPaycomet: unknown;
                        }) => ({
                            amount: input.amount,
                            transactionId: input.idPaycomet,
                            orderId: input.idTpv,
                            description: input.concept,
                        }),
                        response: (input: {
                            resultCode: unknown;
                            ticket: { Status: unknown };
                            resultMessage: unknown;
                        }) => ({
                            status:
                                // eslint-disable-next-line eqeqeq
                                input.resultCode == 0 &&
                                input.ticket &&
                                // eslint-disable-next-line eqeqeq
                                input.ticket?.Status == 0
                                    ? 'OK'
                                    : 'ERROR',

                            message: input.resultMessage,
                            ticket: input.ticket,
                        }),
                    },
                    printLastTransaction: {
                        method: 'GET',
                        uri: `${uri}/v1/transactions/last/print`,
                        params: () => {},
                        response: () => null,
                    },
                };
                break;
        }

        return config;
    };

    return setupConfig;
}

type UseIntervalParams = {
    isActive?: boolean;
    delayMs: number;
};

/**
 * Custom hook for creating an interval that invokes a callback function.
 */
export const useInterval = (
    callback: () => void,
    options: UseIntervalParams,
) => {
    const savedCallback = useRef<() => void>();

    const isActive = options.isActive ?? true;

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        if (!isActive) {
            return;
        }

        const id = setInterval(() => {
            savedCallback.current?.();
        }, options.delayMs);

        return () => {
            clearInterval(id);
        };
    }, [isActive, options.delayMs]);
};

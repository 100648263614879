import React, {
    useState,
    createContext,
    useEffect,
    PropsWithChildren,
} from 'react';
import { useQuery } from 'react-query';
import { Button, Snackbar } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ModalConfirmation from '../layout/ModalConfirmation';
import { useAPI, useLoader, useSocketConnection } from '../lib/hooks';
import { loadConstants } from '../constants';
import {
    APPPROVIDER_INFO,
    APPPROVIDER_ME,
    APPPROVIDER_TRANSLATIONS,
    APPPROVIDER_V7MIGRATIONENDPOINTS,
} from '../lib/queryKeys';
import generalConfig from '../config/general';
import { getErrorMessage } from '../lib/utils';
import { SocketMessage, isObject } from '../types/common';
import { Licence, Sportcenter, User } from '../types/api/models';
import { GetTranslations200 } from '../types/api/paths/Translation';
import { GetUser200 } from '../types/api/paths/User';
import { APIEnv } from '../types/config';
import menus from '../config/menus.json';
import { HttpVerb, MigrationApis } from '../types/api/utils';
import { GetMigrations200 } from '../types/api/paths/Migration';
import { GetInfo200 } from '../types/api/paths/Info';

const preserveLocalStorageValues = [
    'version',
    'environment',
    'lang',
    'registeredDevices',
    'CM5DeviceAddress',
    'CashdroIp',
    'CashdroUser',
    'CashdroPass',
];

type ApiOptions<TData = unknown> = {
    params?: Record<string, unknown>;
    success?: (value: TData) => void;
    error?: (value: unknown) => void;
    loading?: (value: boolean) => void;
    confirmation?: boolean;
};

type AlertType = 'I' | 'W' | 'E' | 'S' | undefined;

type Language = (typeof generalConfig)['languages'][number];

type Constants = ReturnType<typeof loadConstants>;

type Header = {
    title: string;
};

type IAppContext = {
    user?: User;
    privileges?: number[];
    sc?: Sportcenter;
    licence?: Licence;
    config: typeof generalConfig;
    menus: typeof menus;
    apiEnv: APIEnv;
    backendVersion?: string;
    curtesyMinutesForTPV: number;
    /**
     * @deprecated Utilizar config/constants
     */
    constants: Constants;
    t: (translationKey: string) => string;
    setLang: React.Dispatch<React.SetStateAction<Language>>;
    lang: Language;
    /**
     * @deprecated Utilizar services
     */
    api: <TData = unknown>(
        method: HttpVerb,
        resource: string,
        params?: ApiOptions<TData>,
    ) => void;
    getResource: (path: string, method?: HttpVerb) => string;
    migrationApis?: MigrationApis | null;
    showMessage: (type: AlertType, text: string, duration?: number) => void;
    logged: boolean;
    setLoggedUser: (user: User | false) => void;
    header: Header | null;
    setHeader: React.Dispatch<React.SetStateAction<Header | null>>;
    getSocketMessages: () => SocketMessage[];
    deleteReceivedMessage: (message: SocketMessage) => void;
    sendMessageToSocket: (message: SocketMessage) => void;
};

export const AppContext = createContext<IAppContext | undefined>(undefined);

/**
 * Returns the object with the language filtering by its key.
 */
const getLanguageByKey = (languageKey: string | undefined) =>
    generalConfig.languages.find((e) => e.key === languageKey) ||
    generalConfig.languages.find((e) => e.key === 'en')!;

/**
 * Gets the user's language key:
 *      - If it is the first time you log in, the language that
 *      the user has in the browser is used (if it exists in Sporttia)
 *      and if the English language is not used.
 *      - If the user has already entered for the first time, the language
 *      that the user has configured in their profile is used.
 */
const getUserLanguage = () => {
    const storedLanguage = localStorage.getItem('lang');
    const navigatorLanguage = navigator.language.split('-')[0];

    return storedLanguage
        ? getLanguageByKey(storedLanguage)
        : getLanguageByKey(navigatorLanguage);
};

/**
 * Clean local storage, remove all values except for keys declared in 'preserveLocalStorageValues'
 */
const cleanLocalStorage = () => {
    Object.keys(localStorage).forEach((localStorageKey) => {
        if (
            localStorageKey in localStorage &&
            !preserveLocalStorageValues.includes(localStorageKey)
        ) {
            localStorage.removeItem(localStorageKey);
        }
    });
};

/**
 * Data provider to pass data through the entire component tree of the project. This provider is divided into six parts:
 * ApiDocV7, Translations, AlertsUI, Api, CommonData, Authentication
 * and App try not to divide these parts to be able to maintain it in the future correctly.
 */
export function AppProvider({ children }: PropsWithChildren<unknown>) {
    // establecer las funciones de conexión y mensaje por socket
    const [
        openSocket,
        closeSocket,
        receivedMessages,
        sendMessage,
        deleteReceivedMessage,
    ] = useSocketConnection();

    // ApiDocV7 - Aquello relacionado con la integración de v7, esto en el futuro debería eliminarse cuando ya no se use v6.
    // Actualmente lo que se realiza es una petición a https://preapi.sporttia.com:8443/v7/api/doc.json para recuperar todas los endpoints
    // que tenemos funcionando en v7 para poder ser usados, se setea "migrationApis" para que el método api() lo utilice como forma de elegir
    // si un end point se pide con v6 o con v7.
    const [request, migrationApis, setMigrationApis, getResource] = useAPI();
    const { status: v7DocInfoStatus } = useQuery<GetMigrations200>(
        [APPPROVIDER_V7MIGRATIONENDPOINTS],
        () =>
            fetch(
                `${window.runtimeConfig.apiEnv.url}/v7/migration-endpoints?active=true`,
                {
                    method: 'GET',
                },
            ).then((response) => response.json()),
        {
            onSuccess: (data) => {
                setMigrationApis(data?.rows || {});
            },
            onError: () => {
                setMigrationApis([]);
            },
        },
    );

    // Debido a la dependencia de /translations con /info se ha movido de sitio esta query para que los cambios se vean
    // reflejados en la query de /translations que depende de esta, y se ha añadido la logica correspondiente al control
    // de cuando debe actualizarse las traducciones.
    const { data: infoData, status: infoDataStatus } = useQuery(
        [APPPROVIDER_INFO],
        () => request<GetInfo200>('GET', '/info'),
        {
            enabled: migrationApis !== null,
            onSuccess: (data) => {
                localStorage.setItem('environment', data.environment);

                const cachedLastTranslationUpdated = localStorage.getItem(
                    'lastTranslationsUpdate',
                );
                let update = true;
                if (cachedLastTranslationUpdated) {
                    update =
                        new Date(cachedLastTranslationUpdated) <
                        new Date(data.lastTranslationUpdated);
                    if (update) {
                        localStorage.setItem(
                            'lastTranslationsUpdate',
                            data.lastTranslationUpdated,
                        );
                    }
                } else {
                    localStorage.setItem(
                        'lastTranslationsUpdate',
                        data.lastTranslationUpdated,
                    );
                }

                localStorage.setItem('updateTranslations', `${update}`);
            },
        },
    );

    // Translations - Aquello relacionado con las traducciones y el idioma.
    // Inicialmente se hace una petición con GET /translations y se recuperan todas las traducciones y el usuario podrá
    // utilizarlas usando el hook "const {translate} = useTranslations()". Destacar que inicialmente solo se usan
    // las traducciones en el idioma que el usuario use, en el caso de cambiar el idioma se vuelven a pedir las traducciones
    // y se vuelven a setear de ahí el proceso realizado en el .then().
    const [language, setLanguage] = useState(getUserLanguage());

    const { data: translations, status: translationsStatus } = useQuery(
        [
            APPPROVIDER_TRANSLATIONS,
            { page: 1, rows: 5000, web: true, lang: language.key },
        ],
        () => {
            const update = JSON.parse(
                localStorage.getItem('updateTranslations') ?? 'null',
            ) as boolean | null;
            const cachedTranslations = JSON.parse(
                localStorage.getItem('cachedTranslations') ?? 'null',
            ) as GetTranslations200 | null;

            const langTranslations: Record<string, string | undefined> = {};
            if (!update && cachedTranslations !== null) {
                cachedTranslations.rows.forEach((translation) => {
                    langTranslations[translation.code.toLowerCase()] =
                        translation[language.key];
                });
                localStorage.setItem('lang', language.key);
                localStorage.removeItem('updateTranslations');
                return langTranslations;
            }
            return request<GetTranslations200>('GET', '/translations', {
                page: 1,
                rows: 5000,
                web: true,
            }).then((data) => {
                data.rows.forEach((translation) => {
                    langTranslations[translation.code.toLowerCase()] =
                        translation[language.key];
                });
                localStorage.setItem('lang', language.key);
                localStorage.setItem(
                    'cachedTranslations',
                    JSON.stringify(data),
                );
                localStorage.removeItem('updateTranslations');
                return langTranslations;
            });
        },
        { enabled: !!infoData },
    );

    /**
     * Get translation using translation key.
     */
    const translate = (translationKey: string) => {
        const translationKeyLowerCase = translationKey
            ? translationKey.toLowerCase()
            : '';

        if (translations && translationKeyLowerCase in translations) {
            return (
                translations[translationKeyLowerCase] ||
                `[[${translationKeyLowerCase}]]`
            );
        }
        return `[[${translationKeyLowerCase}]]`;
    };

    // AlertsUI - Aquello relacionado con el dialogo de confirmación que usa api() y los mensajes de error que aparecen como un popup en la web.
    // Existen dos componentes en Sporttia genericos un dialogo para confirmar las operaciones de la API y otro componente para mostrar alertas con mensajes
    // de error, warnings o mensajes de éxito (https://mui.com/components/alert/).
    const [confirmationDialog, setConfirmationDialog] = useState<{
        show: boolean;
        onAccept?: () => void;
    }>({
        show: false,
    });
    const [msg, setMsg] = useState<{
        type: Color;
        text: string;
        duration: number;
    } | null>(null);

    /**
     * Show an alert in the app.
     */
    const showMessage = (
        // eslint-disable-next-line @typescript-eslint/default-param-last
        type: AlertType = 'I',
        text: string,
        duration = 5000,
    ) => {
        if (!text) {
            return;
        }

        let messageType: Color;
        switch (type) {
            case 'I':
                messageType = 'info';
                break;
            case 'W':
                messageType = 'warning';
                break;
            case 'E':
                messageType = 'error';
                break;
            case 'S':
                messageType = 'success';
                break;
            default:
                messageType = 'info';
        }

        setMsg({
            type: messageType,
            text,
            duration,
        });
    };

    // CommonData - Aquello relacionado con información común de la aplicación (el /info y el /me básicamente).
    // Cuando se abre por primera vez Sporttia se hace una petición a /info para obtener información del back-end y
    // cuando el usuario se conecta se piden datos a /me para obtener datos de su cuenta, con estos datos se setean
    // diferentes valores en el localStorage y son usados en la aplicación para realizar comprobaciones.
    // PD: He visto que los datos usado a través de localStorage en toda la APP solo son: lang, token, environment, idSc
    // luego he eliminado todos los que se seteaban antes que no se usan ahora.
    const [constants, setConstants] = useState(loadConstants(translate));
    const [userData, setUserData] = useState<GetUser200>({
        user: {
            id: undefined,
            role: undefined,
        },
    });
    const [userLogged, setUserLogged] = useState(
        !!localStorage.getItem('token'),
    );

    // Api - Aquello relacionado con los métodos para llamar a la api.
    // Método usado en Sporttia para hacer peticiones a la API, en el futuro esto debería eliminarse y usar en su caso
    // lo nuevos hooks de servicios (useCitiesService(), useUsersService()...)
    /**
     * Helper method to api (), is where all the work of api () is done.
     * @private
     */
    const apiHelper = <TData,>(
        method: HttpVerb,
        resource: string,
        p: ApiOptions<TData> = {},
    ) => {
        if (p.loading) {
            p.loading(true);
        }

        request(method, resource, p.params || {})
            .then((response) => {
                if (p.confirmation) {
                    setConfirmationDialog({ show: false });
                }

                // request() hace reject en caso de "error 200", de forma que aquí solo puede haber 'success'
                if (p.success) {
                    p.success(response as TData);
                }
            })
            .catch((error) => {
                if (error !== constants.apiLock) {
                    if (p.error) {
                        p.error(error);
                    } else {
                        showMessage('E', getErrorMessage(error));
                    }
                }
            })
            .finally(() => {
                if (p.loading) {
                    p.loading(false);
                }
            });
    };

    /**
     * Method for making API calls.
     * @param method HTTP Verb (GET | POST | PUT | DELETE).
     * @param resource Resource to be requested (/resource).
     * @param p Request params.
     */
    const api = <TData,>(
        method: HttpVerb,
        resource: string,
        p: ApiOptions<TData> = { confirmation: false },
    ) => {
        if (p.confirmation) {
            setConfirmationDialog({
                show: true,
                onAccept: () => apiHelper(method, resource, p),
            });
        } else {
            apiHelper(method, resource, p);
        }
    };

    // Authentication - Aquello relacionado con la autenticación del usuario, básicamente limpieza de datos al
    // hacer logout y seteo de token al iniciar sesión.//
    /**
     * If the value of "user" is not undefined, the user has logged in and the token and the value of "userLogged" are set.
     * On the other hand, if the value of "user" is undefined, the localStorage and the values of "userLogged", "userToken"
     * and "userData" are cleaned (it is left with user: {id: value, role: value} because if it does not fail the topic of
     * headers and so on.).
     * @param user Object with user value from GET /login request.
     */
    const setLoggedUser = (user: User | false) => {
        if (user && user.token) {
            // login, básicamente
            localStorage.setItem('token', user.token);
            setUserLogged(true);
        } else {
            // logout, básicamente
            cleanLocalStorage();
            setUserLogged(false);
            setUserData({
                user: {
                    id: undefined,
                    role: undefined,
                },
            });
        }
    };

    const { status: loggedUserDataStatus } = useQuery(
        [APPPROVIDER_ME],
        () => request<GetUser200>('GET', '/me'),
        {
            enabled: migrationApis !== null && userLogged,
            onSuccess: (data) => {
                function addFieldToModules(sc: Sportcenter) {
                    if (!sc.modules) {
                        return;
                    }

                    const updatedScModules = [...sc.modules];
                    if (sc.moduleGolf) {
                        updatedScModules.push('golf');
                    }
                    if (sc.dataphoneConnected) {
                        updatedScModules.push('dataphoneConnected');
                    }

                    if (sc.cashdroConnected) {
                        updatedScModules.push('cashdroConnected');
                    }

                    return updatedScModules;
                }

                if (data?.user?.lang) {
                    localStorage.setItem('lang', data.user.lang);
                    setLanguage(getLanguageByKey(data.user.lang));
                }

                const newScData = { ...data };
                if (data?.sc?.id) {
                    localStorage.setItem('idSC', `${data.sc.id}`);
                    newScData.sc = {
                        ...data.sc,
                        modules: addFieldToModules(data.sc),
                    };
                }
                setUserData(newScData);
            },
            onError: (err) => {
                if (
                    isObject(err) &&
                    isObject(err.error) &&
                    Array.isArray(err.error.errors) &&
                    isObject(err.error.errors[0]) &&
                    err.error.errors[0].reason === 'PASSWORD_EXPIRED'
                ) {
                    // TODO: encontrar una manera de pedir los datos de GET /info y GET /translations desde aquí, una vez eliminado el usuario localmente.
                    setLoggedUser(false);
                    window.location.reload();
                }
            },
        },
    );

    // CommonData//
    // When the translations change the constants will be updated.
    useEffect(() => {
        if (translations) {
            setConstants(loadConstants(translate));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translations]);

    // For sportcenters, open socket conn. to the v7 back-end
    useEffect(() => {
        if (userData?.user?.role === 'SPORTCENTER') {
            if (typeof openSocket === 'function') {
                openSocket();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData?.user?.role]);

    // Authentication//
    // If the user logged out, close the socket connection (if any).
    useEffect(() => {
        if (!userLogged) {
            if (typeof closeSocket === 'function') {
                closeSocket();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLogged]);

    // Loader - Loader común a todas las llamadas a endpoints de la API para no enseñar la página hasta que el contexto este cargado.//
    const [loading, loader] = useLoader([
        v7DocInfoStatus,
        translationsStatus,
        loggedUserDataStatus,
        infoDataStatus,
    ]);

    const [header, setHeader] = useState<Header | null>(null);

    if (!infoData) {
        return null;
    }

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const state = {
        migrationApis, // ApiDocV7//
        user: userData?.user, // CommonData//
        privileges: userData?.privileges, // CommonData//
        sc: userData?.sc, // CommonData//
        licence: userData?.licence, // CommonData//
        config: generalConfig, // CommonData//
        menus, // CommonData//
        apiEnv: window.runtimeConfig.apiEnv, // CommonData//
        backendVersion: infoData.version, // CommonData//
        curtesyMinutesForTPV: infoData.cortesyMinutesForTPV, // CommonData//
        constants, // CommonData//
        t: translate, // Translations//
        setLang: setLanguage, // Translations//
        lang: language, // Translations//
        api, // Api//
        getResource, // Api//
        showMessage, // AlertsUI//
        logged: userLogged, // Authentication//
        setLoggedUser, // Authentication//
        header, // App//
        setHeader, // App//
        getSocketMessages: () => receivedMessages,
        deleteReceivedMessage,
        sendMessageToSocket: sendMessage,
    };

    if (loading) {
        return loader;
    }

    return (
        <AppContext.Provider value={state}>
            {children}
            <ModalConfirmation
                show={confirmationDialog.show}
                onClose={() => setConfirmationDialog({ show: false })}
                onAccept={confirmationDialog.onAccept}
            />
            {msg !== null && (
                <Snackbar
                    open
                    autoHideDuration={msg.duration}
                    onClose={() => setMsg(null)}
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        onClose={() => setMsg(null)}
                        severity={msg.type}
                        style={{ whiteSpace: 'pre-line' }}
                        action={
                            <Button
                                onClick={() => {
                                    const sarid = msg.text.split(' ').pop()!;
                                    navigator.clipboard
                                        .writeText(sarid)
                                        .then(() => {});
                                    setMsg(null);
                                }}
                            >
                                <FileCopyIcon style={{ color: '#FFF' }} />
                            </Button>
                        }
                    >
                        {msg.text}
                    </Alert>
                </Snackbar>
            )}
        </AppContext.Provider>
    );
}

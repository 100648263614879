import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles(() => ({
    golfCard: {
        width: '180px',
        height: '80px',
        border: '3px solid #E0E0E0',
        borderRadius: '8px',
        margin: '5px',
        padding: '5px',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(33, 150, 243, 0.5)',
        },
    },
}));

/**
 * Draw golf card.
 * @param onClick Action taken when user click on booking card.
 * @returns {JSX.Element}
 */
export default function GolfBookingCard({ booking, onSelect }) {
    const classes = useStyles();

    // eslint-disable-next-line no-lone-blocks
    {
        /* Render golf card. */
    }
    return (
        <Box className={classes.golfCard} onClick={onSelect}>
            <Grid container style={{ height: '100%' }} alignItems="center">
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                    <Typography variant="h5">{booking.time}</Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                    <PersonIcon />
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                    <Box
                        style={{
                            backgroundColor:
                                // eslint-disable-next-line no-nested-ternary
                                booking.players.length === 4
                                    ? 'red'
                                    : booking.players.length === 0
                                      ? 'green'
                                      : 'yellow',
                            padding: 4,
                            borderRadius: 3,
                        }}
                    >
                        {booking.players.length}/4
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { useQuery, useQueryClient } from 'react-query';
import { AppContext } from '../../../../sporttia/AppContext';
import { SttFullDialog } from '../../../../sporttia/dialogs/SttFullDialog';
import { SttTabs } from '../../../../sporttia/SttTabs';
import AdminCustomerDetails from './AdminCustomerDetails';
import AdminCustomerSubscriptions from './AdminCustomerSubscriptions';
import { SUPERADMIN_CUSTOMER } from '../../../../lib/queryKeys';
import useCustomersService from '../../../../services/CustomersService';

/**
 * Customer view. Contains tabs to access customer data.
 * @param id Customer ID.
 * @param onClose Function executed when closing the full dialog.
 * @returns {JSX.Element}
 */
export default function AdminCustomer({ id, onClose }) {
    const cxt = useContext(AppContext);
    const queryClient = useQueryClient();
    const customersService = useCustomersService();
    const [customerId, setCustomerId] = useState(id);
    const customerQuery = useQuery(
        [SUPERADMIN_CUSTOMER, { id: customerId }],
        () => customersService.get(customerId),
        { enabled: !!customerId && customerId !== 'create' },
    );

    /**
     * Update customer id if id prop was changed.
     */
    useEffect(() => {
        setCustomerId(id);
    }, [id]);

    return (
        <SttFullDialog
            open={customerId !== null}
            onClose={onClose}
            title={
                // eslint-disable-next-line no-nested-ternary
                customerQuery?.isLoading
                    ? ''
                    : customerQuery?.data?.customer?.id
                      ? customerQuery?.data?.customer?.name
                      : cxt.t('admin.customer.create')
            }
        >
            <Paper>
                <Box p={3}>
                    <SttTabs
                        tabs={[
                            {
                                caption: cxt.t('Detail'),
                                component: (
                                    <AdminCustomerDetails
                                        customer={customerQuery?.data?.customer}
                                        status={customerQuery?.status}
                                        onCreate={setCustomerId}
                                        onUpdate={(data) =>
                                            queryClient.setQueryData(
                                                [
                                                    SUPERADMIN_CUSTOMER,
                                                    { id: data?.customer?.id },
                                                ],
                                                data,
                                            )
                                        }
                                        onDelete={onClose}
                                    />
                                ),
                            },
                            {
                                caption: cxt.t('admin.customer.subscriptions'),
                                show: customerId !== 'create',
                                component: (
                                    <AdminCustomerSubscriptions
                                        customerId={customerId}
                                    />
                                ),
                            },
                        ]}
                    />
                </Box>
            </Paper>
        </SttFullDialog>
    );
}

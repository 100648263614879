import React, { useState, useEffect, useContext } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { AppContext, SttDialog, SttButtonMonths, SttButtonYears } from '../all';

/**
 * Dialog calendar
 * ===============
 *
 * Props
 *
 * 	* boolean open
 * 	* string date: Y-m-d
 * 	* function onClickDate(date): Y-m-d
 * 	* function onClose
 */

export function SttCalendarDialog({
    open = false,
    date,
    onSelectDate,
    onClose,
}) {
    const cxt = useContext(AppContext);
    const [year, setYear] = useState(parseInt(moment().format('YYYY')));
    const [month, setMonth] = useState(parseInt(moment().format('M')));

    const calendarHeader = [
        cxt.t('day.1.mini'),
        cxt.t('day.2.mini'),
        cxt.t('day.3.mini'),
        cxt.t('day.4.mini'),
        cxt.t('day.5.mini'),
        cxt.t('day.6.mini'),
        cxt.t('day.0.mini'),
    ];

    /**
     * Calendar struct
     *
     *	  [
     *		{
     *			days: [
     *				{cap:'L'},
     *				{cap:'M'},
     *				...
     *			]
     *		},
     *		{
     *			days: [
     *				{cap:""},
     *				{cap:"1", date:"2020-02-01"},
     *				{cap:"2", date:"2020-02-02"},
     *	  			...
     *	  		]
     *	  	}
     *	  ]
     */
    const [calendar, setCalendar] = useState();

    /**
     * Crear datos del calendario
     */
    const createCalendar = () => {
        const momentDate = moment(
            `${year}-${month.toString().padStart(2, '0')}-01`,
        );

        const numDaysOfMonth = parseInt(momentDate.endOf('month').format('D'));
        const firstDayMonth = momentDate.startOf('month').format('E');

        // Init calendar
        const cal = [];

        // Add cells
        let week = { days: [] };
        let currentCell = 1;
        let currentDay = 0;
        do {
            currentDay = currentCell - parseInt(firstDayMonth) + 1;

            if (currentDay > 0) {
                week.days.push({
                    cap: momentDate.format('D'),
                    date: momentDate.format('YYYY-MM-DD'),
                });
                momentDate.add(1, 'day');
            } else {
                week.days.push({ cap: '' });
            }

            // Next week
            if (week.days.length === 7) {
                cal.push(week);
                week = { days: [] };
            } else if (currentDay >= numDaysOfMonth) {
                // If we're over, add the next week filling the empty days
                week = {
                    days: week.days.concat(
                        new Array(7 - week.days.length).fill({ cap: '' }),
                    ),
                };
                cal.push(week);
            }

            currentCell++;
        } while (currentDay < numDaysOfMonth);

        setCalendar(cal);
    };

    /**
     * Click on cell
     */
    const clickOnCell = (cell) => {
        if (cell.date && onSelectDate) {
            onSelectDate(cell.date);
        }
    };

    /**
     * Year, month change
     */
    useEffect(() => {
        if (year && month) {
            createCalendar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year, month]);

    // Render
    return (
        <SttDialog
            title={cxt.t('Calendar')}
            open={open}
            onClose={() => onClose()}
            content={
                <>
                    <Box mb={2} align="center">
                        <SttButtonYears
                            year={year}
                            raius={3}
                            onSelectYear={setYear}
                        />
                    </Box>

                    <Box mb={2} align="center">
                        <SttButtonMonths
                            contract="xs"
                            tinyLabels
                            selectedMonths={[month]}
                            onChangeMonth={setMonth}
                        />
                    </Box>

                    <Box style={{ textAlign: 'center', marginTop: 15 }}>
                        <Box style={{ display: 'flex' }}>
                            {calendarHeader.map((value, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <CalendarHeaderCell key={i} value={value} />
                            ))}
                        </Box>
                        {calendar &&
                            calendar.map((week, numWeek) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Box key={numWeek} style={{ display: 'flex' }}>
                                    {week &&
                                        week.days &&
                                        week.days.map((cell, i) => (
                                            <CalendarCell
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={i}
                                                cell={cell}
                                                date={date}
                                                onClick={() =>
                                                    clickOnCell(cell)
                                                }
                                            />
                                        ))}
                                </Box>
                            ))}
                    </Box>
                </>
            }
            buttons={[
                {
                    type: 'close',
                    onClick: onClose,
                },
            ]}
        />
    );
}

function CalendarHeaderCell({ value }) {
    const useStyles = makeStyles({
        cell: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 2,
            cursor: 'default',
            backgroundColor: '#eaeaea',
            height: 30,
            userSelect: 'none',
        },
    });

    const classes = useStyles();

    return <Box className={classes.cell}>{value}</Box>;
}

/**
 * Calendar cell
 */
function CalendarCell({ cell, date, onClick }) {
    const useStyles = makeStyles({
        cell: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 50,
            margin: 2,
            cursor: 'pointer',
            userSelect: 'none',
            backgroundColor: (props) =>
                // eslint-disable-next-line no-nested-ternary
                date && date === props.date
                    ? '#c5c5c5'
                    : props.cap
                      ? '#eaeaea'
                      : null,
        },
    });

    const classes = useStyles(cell);

    return (
        <Box className={classes.cell} onClick={onClick}>
            {cell.cap}
        </Box>
    );
}

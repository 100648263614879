/* eslint-disable no-nested-ternary */
import React, { useState, useContext } from 'react';
import {
    Box,
    CircularProgress,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';
import { AppContext, SttDialog } from '../../sporttia/all';
import DeviceSpace from '../../types/models/DeviceSpace';
import useScsService from '../../services/ScsService';
import { useTranslations } from '../../lib/hooks';
import translations from '../../translations';
import DeviceSpaceDialog from './DeviceSpaceDialog';

type CapacityMonitorDialogProps = {
    onClose: () => void;
};

function getDeviceSpaceColor(currentCapacity: number, maximumCapacity: number) {
    const percentageFilled = currentCapacity / maximumCapacity;
    return percentageFilled < 0.5
        ? '#067502'
        : percentageFilled < 0.8
          ? '#c79200'
          : percentageFilled < 1.0
            ? '#730121'
            : '#730121';
}

export default function CapacityMonitorDialog({
    onClose,
}: CapacityMonitorDialogProps) {
    const cxt = useContext(AppContext)!;
    const scsService = useScsService();
    const { translate } = useTranslations();
    const [deviceSpace, setDeviceSpace] = useState<DeviceSpace | null>(null);
    const devicesSpacesQuery = scsService.useGetScsDevicesCapacities(
        // @ts-expect-error hay prisa
        cxt?.sc?.id,
        {},
        {
            refetchInterval: 300000, // milliseconds
        },
    );
    if (devicesSpacesQuery.isLoading) {
        return (
            <SttDialog
                maxWidth="md"
                open
                content={
                    <Box display="flex" justifyContent="center" paddingY={15}>
                        <CircularProgress />
                    </Box>
                }
                title={undefined}
                onClose={undefined}
                buttons={undefined}
            />
        );
    }

    return (
        <>
            <SttDialog
                title={translate(
                    translations.deviceSpace.devicesCapacityMonitor,
                )}
                maxWidth="md"
                open
                onClose={onClose}
                content={
                    <Grid container spacing={3} direction="row">
                        {devicesSpacesQuery?.data?.rows.map(
                            (item: DeviceSpace) => (
                                <Grid item>
                                    <Paper
                                        key={deviceSpace?.id}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            height: 40,
                                            backgroundColor:
                                                getDeviceSpaceColor(
                                                    item?.currentCapacity,
                                                    item?.maximumCapacity,
                                                ),
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: 'auto',
                                            padding: 10,
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                color: 'white',
                                                fontWeight: 'bold',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => setDeviceSpace(item)}
                                        >
                                            {`${item?.name}: ${item?.currentCapacity} / ${item?.maximumCapacity}`}
                                        </Typography>
                                    </Paper>
                                </Grid>
                            ),
                        )}
                    </Grid>
                }
                buttons={undefined}
            />
            {deviceSpace && (
                <DeviceSpaceDialog
                    item={deviceSpace}
                    onSave={() => {
                        setDeviceSpace(null);
                        devicesSpacesQuery.refetch();
                    }}
                    onClose={() => {
                        setDeviceSpace(null);
                    }}
                />
            )}
        </>
    );
}

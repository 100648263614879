import React, { useState, useEffect, useContext } from 'react';
import { Paper } from '@material-ui/core';
import {
    AppContext,
    SttTopControls,
    SttTable,
    SttChipFileFormat,
    SttChipFileType,
    SttFileDialog,
    SttButtonFab,
    SttUploadDialog,
} from '../../sporttia/all';
import { copyToClipboard, updateElementInArray } from '../../lib/utils';
import DateBuilder from '../../lib/DateBuilder';

export default function Files() {
    const cxt = useContext(AppContext);
    const [files, setFiles] = useState();
    const [openFileId, setOpenFileId] = useState();
    const [uploadFileOpen, setUploadFileOpen] = useState(false);
    const [filters, setFilters] = useState({
        name: '',
        type: null,
    });

    /**
     * Load files
     */
    const loadFiles = (p = { page: 1, rows: 20 }) => {
        cxt.api('GET', `/scs/${cxt.sc.id}/files`, {
            params: {
                ...filters,
                page: p.page,
                rows: p.rows,
            },
            success: (r) => {
                setFiles(r);
            },
        });
    };

    /**
     * Open file
     */
    const openFile = (file) => {
        setOpenFileId(file.id);
    };

    /**
     * Update file
     */
    const updateFile = (file) => {
        setFiles({
            ...files,
            rows: updateElementInArray(files.rows, file),
        });
        setOpenFileId(null);
    };

    /**
     * Init
     */
    useEffect(() => {
        cxt.setHeader({
            title: cxt.t('Documents'),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -----| Render |-----
    return (
        <Paper>
            <SttTopControls
                p={2}
                fields={[
                    {
                        caption: cxt.t('Name'),
                        name: 'name',
                        type: 'text',
                        value: filters.name,
                        onEnterKey: loadFiles,
                    },
                ]}
                extraFields={[
                    {
                        caption: cxt.t('Type'),
                        name: 'type',
                        type: 'select',
                        value: filters.type,
                        options: cxt.constants.fileTypes,
                        cleanable: true,
                    },
                    {
                        caption: cxt.t('Format'),
                        name: 'format',
                        type: 'select',
                        value: filters.format,
                        options: cxt.constants.fileFormats,
                        cleanable: true,
                    },
                ]}
                onChange={({ name, value }) =>
                    setFilters({ ...filters, [name]: value })
                }
                onFilter={loadFiles}
            />

            <SttTable
                data={files}
                columns={[
                    {
                        title: '#',
                        type: 'tooltip',
                        value: (row) => ({ label: '#', value: row.id }),
                        onClick: (row) =>
                            copyToClipboard(row.id, () =>
                                cxt.showMessage(
                                    'S',
                                    cxt.t('copiedToClipboard'),
                                ),
                            ),
                    },
                    {
                        title: cxt.t('Created'),
                        field: 'created',
                        value: (row) =>
                            new DateBuilder(
                                row.created,
                                cxt?.sc?.timezone?.name,
                                cxt?.lang?.key,
                            ).dmy(),
                    },
                    {
                        type: 'link',
                        align: 'left',
                        title: cxt.t('Name'),
                        value: (row) => row.name || cxt.t('Noname'),
                        onClick: (row) => openFile(row),
                    },
                    {
                        title: cxt.t('Type'),
                        field: 'type',
                        value: (row) => <SttChipFileType type={row.type} />,
                    },
                    {
                        title: cxt.t('Format'),
                        value: (row) => (
                            <SttChipFileFormat format={row.format} />
                        ),
                    },
                    {
                        type: 'binaryStatus',
                        title: cxt.t('Public'),
                        field: 'public',
                    },
                ]}
                onFetch={loadFiles}
            />

            <SttButtonFab onClick={() => setUploadFileOpen(true)} />

            <SttUploadDialog
                open={uploadFileOpen}
                types={['SC', 'FACILITY', 'USER_IMPORT', 'Q60_MOD_2']}
                onClose={() => setUploadFileOpen(false)}
                onUploaded={() => {
                    setUploadFileOpen(false);
                    loadFiles();
                }}
            />

            <SttFileDialog
                idFile={openFileId}
                onClose={() => setOpenFileId(null)}
                onUpdate={updateFile}
            />
        </Paper>
    );
}

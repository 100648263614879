import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { SttFullDialog } from '../../../../sporttia/dialogs/SttFullDialog';
import { useLoader, useTranslations } from '../../../../lib/hooks';
import { AppContext } from '../../../../sporttia/AppContext';
import { SUPERADMIN_TRANSLATION } from '../../../../lib/queryKeys';
import useCitiesService from '../../../../services/CitiesService';
import SttValidatedForm from '../../../../sporttia/SttValidatedForm';
import translations from '../../../../translations';
/**
 * City view, contains city form.
 * @param id City ID.
 * @param onClose Function executed when closing the full dialog.
 * @param onCreate Action taken when creating the translation.
 * @param onUpdate Action taken when updating the translation.
 * @param onDelete Method executed when removing translation.
 * @returns {JSX.Element}
 */
export default function AdminCity({
    id = null,
    onClose,
    onCreate,
    onUpdate,
    onDelete,
}) {
    const cxt = useContext(AppContext);
    const queryClient = useQueryClient();
    const citiesService = useCitiesService();
    const form = useForm();
    const [cityId, setCityId] = useState(id);
    const { translate } = useTranslations();
    const cityQuery = useQuery(
        [SUPERADMIN_TRANSLATION, { id: cityId }],
        () => citiesService.get(cityId),
        { enabled: !!cityId && cityId !== 'create' },
    );
    const deleteMutation = useMutation(
        (params) => citiesService.remove(params?.id),
        {
            onSuccess: () => {
                onDelete();
            },
        },
    );
    const updateMutation = useMutation(
        (params) => citiesService.update(params?.id, params?.params),
        {
            onSuccess: (data) => {
                queryClient.setQueryData(
                    [SUPERADMIN_TRANSLATION, { id: data?.city?.id }],
                    data,
                );
                onUpdate(data);
            },
        },
    );
    const createMutation = useMutation(
        (params) => citiesService.create(params?.params),
        {
            onSuccess: (data) => {
                queryClient.setQueryData(
                    [SUPERADMIN_TRANSLATION, { id: data?.city?.id }],
                    data,
                );
                onCreate(data);
            },
        },
    );
    const [, loader] = useLoader([
        deleteMutation.status,
        updateMutation.status,
        createMutation.status,
    ]);

    /**
     * Method executed when the user clicks on save or create city.
     * @param formData Form data.
     */
    const mutateCity = (formData) => {
        if (cityId !== 'create') {
            updateMutation.mutate({
                id: cityId,
                params: {
                    name: formData.name,
                    idCountry: formData?.country?.id,
                    mapX: formData?.map?.mapX,
                    mapY: formData?.map?.mapY,
                },
            });
        } else {
            createMutation.mutate({
                params: {
                    name: formData.name,
                    idCountry: formData?.country?.id,
                    mapX: formData?.map?.mapX,
                    mapY: formData?.map?.mapY,
                },
            });
        }
    };

    /**
     * When the "city" data is returned by the API, it is set in the form.
     */
    useEffect(() => {
        if (cityQuery?.status === 'success') {
            form.reset({
                name: cityQuery?.data?.city?.name,
                country: cityQuery?.data?.city?.country,
                map: {
                    mapX: cityQuery?.data?.city?.mapX,
                    mapY: cityQuery?.data?.city?.mapY,
                },
            });
        } else {
            form.reset({
                name: '',
            });
        }
    }, [cityQuery?.data?.city, form, cityQuery?.status]);

    /**
     * Update city id if id prop was changed and reset form values.
     */
    useEffect(() => {
        setCityId(id);
        form.reset();
    }, [id, form]);

    if (
        updateMutation?.isSuccess ||
        createMutation?.isSuccess ||
        deleteMutation?.isSuccess
    ) {
        cxt?.showMessage(
            'S',
            translate(translations.generic.OperationSuccessful),
        );

        if (updateMutation?.isSuccess) updateMutation.reset();
        if (deleteMutation?.isSuccess) deleteMutation.reset();
        if (createMutation?.isSuccess) createMutation.reset();
    }

    return (
        <SttFullDialog
            open={cityId !== null}
            onClose={onClose}
            title={
                // eslint-disable-next-line no-nested-ternary
                cityQuery?.isLoading
                    ? ''
                    : cityQuery?.data?.city?.id
                      ? cityQuery?.data?.city?.name
                      : cxt.t('CreateCity')
            }
        >
            {loader}
            <Paper>
                <Box p={3}>
                    <SttValidatedForm
                        form={form}
                        loadingData={cityQuery.status === 'loading'}
                        fields={[
                            {
                                type: 'textinput',
                                name: 'name',
                                caption: cxt.t('Name'),
                            },
                            {
                                type: 'country',
                                name: 'country',
                                value: cityQuery?.data?.city?.country || null,
                            },
                            {
                                type: 'map',
                                name: 'map',
                                value: {
                                    mapX:
                                        Number(cityQuery?.data?.city?.mapX) ||
                                        41.390205,
                                    mapY:
                                        Number(cityQuery?.data?.city?.mapY) ||
                                        2.154007,
                                },
                            },
                        ]}
                        buttons={[
                            {
                                show: !cityQuery?.data?.city?.trash,
                                type: 'save',
                                onClick: form.handleSubmit((formData) =>
                                    mutateCity(formData),
                                ),
                            },
                            {
                                show:
                                    cityQuery?.data?.city?.id &&
                                    !cityQuery?.data?.city?.trash,
                                type: 'delete',
                                onClick: () =>
                                    deleteMutation.mutate({ id: cityId }),
                            },
                        ]}
                    />
                </Box>
            </Paper>
        </SttFullDialog>
    );
}

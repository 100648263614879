import React, { useContext, useState } from 'react';
import { Box } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { SttForm } from '../../sporttia/SttForm';
import { SttDialog } from '../../sporttia/dialogs/SttDialog';
import { AppContext } from '../../sporttia/AppContext';
import { useInteractionsFiles, useLoader } from '../../lib/hooks';

export default function Q60DownloadDialog({
    open = false,
    onClose,
    filters = {},
}) {
    const cxt = useContext(AppContext);
    const { downloadFile } = useInteractionsFiles();
    const [downloadStatus, setDownloadStatus] = useState('idle');
    const [q60Params, setQ60Params] = useState({
        codeTramitter: 30820,
        presenterEntity: '0081',
        presenterOffice: 1045,
        paidIni: new Date(),
        paymentDate: new Date(),
        ccc: null,
        collectorEntity: 81,
        collectorOffice: 1045,
        paidEnd: new Date(),
        validityDate: new Date(),
        taxCode: 35,
    });

    const downloadQ60 = () => {
        downloadFile(
            `/scs/${cxt?.sc?.id}/payments/q60mod2`,
            'application/pdf',
            filters,
            undefined,
            'POST',
            { period: q60Params.paidEnd },
            (response) => {
                const { errors } = response.error;
                if (errors && errors.length >= 1) {
                    const formattedErrors = {
                        dniError:
                            errors && errors.find((error) => error.dni)
                                ? 'No tiene dni asignado.'
                                : '',
                        fullnameError: errors.find((error) => error.fullname)
                            ? 'No tiene nombre completo asignado.'
                            : '',
                        userId: errors[0].userId ?? '',
                    };
                    cxt.showMessage(
                        'E',
                        `El usuario con id ${formattedErrors.userId ? formattedErrors.userId : ''}: ${formattedErrors.fullnameError} ${formattedErrors.dniError}`,
                    );
                } else {
                    cxt.showMessage('E', `${response.error.msg} }`);
                }

                setDownloadStatus('success');
            },
            () => {
                setDownloadStatus('success');
            },
        );
    };

    /**
     * Handle form.
     * @param name Key value.
     * @param value Value to be set.
     */
    const handleForm = ({ name, value }) => {
        setQ60Params({ ...q60Params, [name]: value });
    };

    const [, loader] = useLoader([downloadStatus]);

    return (
        <SttDialog
            maxWidth="md"
            open={open}
            onClose={onClose}
            title="Cuaderno 60"
            content={
                <>
                    {loader}
                    <Paper>
                        <Box p={2}>
                            <SttForm
                                elements={[
                                    /* {
                                    xs: 4,
                                    caption: cxt.t('hart.c60.codetramitter'),
                                    name: 'codeTramitter',
                                    value: q60Params.codeTramitter,
                                },
                                {
                                    xs: 4,
                                    caption: cxt.t('hart.c60.presenterEntity'),
                                    name: 'presenterEntity',
                                    value: q60Params.presenterEntity,
                                },
                                {
                                    xs: 4,
                                    caption: cxt.t('hart.c60.presenterOffice'),
                                    name: 'presenterOffice',
                                    value: q60Params.presenterOffice,
                                },
                                {
                                    xs: 4,
                                    caption: cxt.t('DateIni'),
                                    name: 'paidIni',
                                    type: 'date',
                                    value: q60Params.paidIni,
                                }, */
                                    {
                                        xs: 4,
                                        caption: cxt.t('DateEnd'),
                                        name: 'paidEnd',
                                        type: 'date',
                                        value: q60Params.paidEnd,
                                    },
                                    /* {
                                    xs: 4,
                                    caption: 'CCC',
                                    name: 'ccc',
                                    value: q60Params.ccc,
                                },
                                {
                                    xs: 4,
                                    caption: cxt.t('hart.c60.collectorEntity'),
                                    name: 'collectorEntity',
                                    value: q60Params.collectorEntity,
                                },
                                {
                                    xs: 4,
                                    caption: cxt.t('hart.c60.collectorOffice'),
                                    name: 'collectorOffice',
                                    value: q60Params.collectorOffice,
                                },
                                {
                                    xs: 4,
                                    caption: cxt.t('hart.c60.taxCode'),
                                    name: 'taxCode',
                                    value: q60Params.taxCode,
                                }, */
                                ]}
                                onChangeForm={handleForm}
                            />
                        </Box>
                    </Paper>
                </>
            }
            buttons={[
                {
                    caption: cxt.t('Generate'),
                    type: 'accept',
                    onClick: () => {
                        setDownloadStatus('loading');
                        downloadQ60();
                    },
                },
                {
                    caption: cxt.t('Close'),
                    onClick: () => {
                        onClose();
                        setDownloadStatus('success');
                    },
                },
            ]}
        />
    );
}
